<template>
  <div class="ad-list">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col :span="22">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>礼包订单</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>
    <el-container>
      <!--分类-->
      <el-aside width="200px">
        <el-tree :data="treedata" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
      </el-aside>
      <el-main>
        <!-- 列表内容 -->
        <div class="main">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item>
              <el-input v-model="orderno" placeholder="订单编号"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="collectphone" placeholder="手机号"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="nickname" placeholder="下单用户"></el-input>
            </el-form-item>
            <el-form-item label="创建时间段">
              <el-date-picker
                v-model="paytime"
                type="daterange"
                @change="paytimechange"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onCurrentChange(1)">查询</el-button>
            </el-form-item>
          </el-form>
          <el-table :data="adminList" border="" style="width: 100%">
            <el-table-column prop="id" label="id" width="95" align="center"></el-table-column>
            <el-table-column prop="orderno" label="订单编号" width="200" align="center"></el-table-column>
            <el-table-column prop="ordernopay" label="支付单号" width="210" align="center"></el-table-column>
            <el-table-column prop="nickname" label="下单用户"  width="110" align="center"></el-table-column>
            <el-table-column prop="collectphone" label="手机号"  width="120" align="center"></el-table-column>
            <el-table-column prop="price" label="商品总价"  width="95" align="center"></el-table-column>
            <el-table-column prop="price" label="支付状态"  width="95" align="center">
              <template slot-scope="scope">
           {{ ["未付款", "已付款", "免支付",'已退款'][scope.row.ispay] }}
              </template>
            </el-table-column>
             <el-table-column prop="gradeno" label="关联会员等级"></el-table-column>
        <el-table-column prop="productids" label="关联商品"  width="300">
          <template slot-scope="scope">
            <p v-for="(item, index) in scope.row.productids" :key="index">{{index+1}}:{{item}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="couponids" label="关联优惠券"  width="300">
                <template slot-scope="scope">
            <p v-for="(item, index) in scope.row.couponids" :key="index">{{index+1}}:{{item}}</p>
          </template>
        </el-table-column>
            <el-table-column prop="addtime" label="创建时间"  width="160"  align="center"></el-table-column>
                        <el-table-column prop="paytime" label="支付时间"  width="160"  align="center"></el-table-column>
            <el-table-column label="操作" fixed="right" width="80" align="center">
              <template slot-scope="scope">
                <el-button size="mini" @click="godetail(scope.row.orderno)">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="pagination">
          <el-pagination
            background=""
            layout="prev, pager, next"
            :page-size="5"
            :current-page="pageIndex"
            :total="pageTotal"
            @current-change="onCurrentChange"
          />
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import {
  getbagList,
  paymentRequest,
  setStatesRequest,
  setpayRequest,
  exportOrderRequest,
  refundorderRequest
} from "@/api/fj_order";
export default {
  name: "advlist",

  data() {
    return {
      id: 0,
      orderno: "",
      nickname: "",
      userphone: "",
      giftenddate: [],
      giftenddatestr: "",
      paytime: [],
      paytimestr: "",
      shopname: "",
      iType: -1,
      adminList: [],
      userName: "",
      pageIndex: 1,
      pageTotal: 0,
      dialogTableVisible: false,
      dialogTableVisible2: false,
      collectphone: "",
      jumsg: "",
      typeId: -1,
      treedata: [
        {
          label: "全部",
          value: -1
        },
        {
          label: "未付款",
          value: 0
        },
        {
          label: "已付款",
          value: 1
        }
      ],
      defaultProps: {
        children: "children",
        label: "label"
      },
      dfrow: {}
    };
  },

  watch: {
    $route: {
      handler(to, from) {
        this.typeId = to.query.typeId || -1;

        this.onCurrentChange(1);
      },
      immediate: true
    }
  },

  created() {
    this.getList();
  },
  methods: {
    getList() {
      getbagList({
        orderno: this.orderno,
        nickname: this.nickname,
        userphone: this.userphone,
        giftenddate: this.giftenddatestr,
        paytime: this.paytimestr,
        shopname: this.shopname,
        collectphone: this.collectphone,
        states: this.typeId,
        pageNum: this.pageIndex,
        pageSize: 5
      }).then(res => {
        let data = res.data;
        this.adminList = data.data;
            this.adminList.map(item => {
          item.productids = item.productids.split(",");
           item.couponids = item.couponids.split(",");
        });
        this.pageTotal = data.count;
      });
    },
    rexport() {
      //导出
      exportOrderRequest({
        orderno: this.orderno,
        nickname: this.nickname,
        userphone: this.userphone,
        giftenddate: this.giftenddatestr,
        collectphone: this.collectphone,
        paytime: this.paytimestr,
        shopname: this.shopname,
        states: this.typeId
      }).then(res => {
        let blob = new Blob([res], {
          type: "application/octet-stream"
        }); // 转化为blob对象
        console.log(blob);
        let filename = "订单.xlsx"; // 判断是否使用默认文件名
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          window.navigator.msSaveBlob(blob, filename);
        } else {
          var blobURL = window.URL.createObjectURL(blob); // 将blob对象转为一个URL
          var tempLink = document.createElement("a"); // 创建一个a标签
          tempLink.style.display = "none";
          tempLink.href = blobURL;
          tempLink.setAttribute("download", filename); // 给a标签添加下载属性
          if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank");
          }
          document.body.appendChild(tempLink); // 将a标签添加到body当中
          tempLink.click(); // 启动下载
          document.body.removeChild(tempLink); // 下载完毕删除a标签
          window.URL.revokeObjectURL(blobURL);
        }
      });
    },
    paytimechange(e) {
      console.info(e);
      this.paytimestr = e.join(",");
    },
    handleNodeClick(data) {
      this.$router.push({
        path: "/fj_orderbag/list",
        query: { typeId: data.value }
      });
    },

    godetail(orderno) {
      this.$router.push({
        path: "/fj_order/edit",
        query: { orderbagno: orderno }
      });
    },

    setStates(id, ispay, row) {
      let msg = "是否确认发货";
      if (ispay == 3) {
        msg = "是否确认完成";
      }

      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          setStatesRequest({
            id: id,
            states: ispay
          }).then(res => {
            if (res.code == 1) {
              this.$message(res.msg);
              row.states = ispay;
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },

    refundorder(orderno, ispay, row) {
      let msg = "是否确认退款";

      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          refundorderRequest({
            orderno: orderno
          }).then(res => {
            if (res.code == 1) {
              this.$message(res.msg);
              this.onCurrentChange(1);
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },

    setpay(id, row, ispay) {
      this.$confirm("是否确认该订单已经线下收款", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          setpayRequest({
            id: id,
            ispay,
            ispay
          }).then(res => {
            if (res.code == 1) {
              this.$message(res.msg);
              row.ispay = ispay;
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    onpay(row) {
      this.$confirm("确认打款吗；直接到会员提现的微信账户", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          paymentRequest({
            id: row.id
          }).then(res => {
            if (res.code == 1) {
              this.$message(res.msg);
              row.status = 4;
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },

    onCurrentChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getList();
    }
  }
};
</script> 

<style lang="less" scoped>
</style>